import { useNotifications } from './context';
import { DEFAULT_ERROR_DURATION, DEFAULT_SUCCESS_DURATION } from './lib/duration';

export interface NotifyHook {
  success: (title: string, duration?: number) => void;
  error: (title: string, duration?: number) => void;
}

export function useNotify(): NotifyHook {
  const notifications = useNotifications();

  return {
    success: (title: string, duration?: number) =>
      notifications.add({
        title,
        type: 'success',
        duration: duration ?? DEFAULT_SUCCESS_DURATION,
      }),
    error: (title: string, duration?: number) =>
      notifications.add({
        title,
        type: 'error',
        duration: duration ?? DEFAULT_ERROR_DURATION,
      }),
  };
}
