import { IconCross, IconTick } from '@newfront-insurance/core-ui';
import { useEffect } from 'react';

import { useNotifications } from '../context';
import { DEFAULT_ERROR_DURATION, DEFAULT_SUCCESS_DURATION } from '../lib/duration';
import type { Notification } from '../types';

interface Props {
  id: string;
  notification: Notification;
}

export function NotificationItem(props: Props): JSX.Element {
  const { id, notification } = props;
  const { title, type } = notification;
  const defaultDuration = type === 'error' ? DEFAULT_ERROR_DURATION : DEFAULT_SUCCESS_DURATION;
  const { duration = defaultDuration } = notification;
  const notifications = useNotifications();

  useEffect(() => {
    if (duration <= 0) return () => undefined;
    const timeout = setTimeout(() => notifications.remove(id), duration);
    return (): void => {
      clearTimeout(timeout);
    };
  }, [duration, id, notifications]);

  return (
    <div
      className={`
        relative mb-2 flex min-w-[300px] max-w-[400px] items-center
        whitespace-pre-line rounded-sm p-4
        pr-10 text-white shadow-[2px_2px_16px_rgba(38,45,70,0.15)]
        ${(type === 'success' && 'bg-grass-400') || (type === 'error' && 'bg-fire-500') || 'bg-[#042553]'}
      `}
    >
      <div className="flex w-full flex-row items-center gap-4" data-testId="notification-box">
        {type === 'success' && <IconTick size={16} />}
        {type === 'error' && <IconCross color="#ffffff" size={16} />}
        <span
          className={`text-center text-white ${
            type === 'success' || type === 'error' ? 'text-sm leading-[18px]' : 'text-base leading-[21px]'
          }`}
          data-testId="notification-text"
        >
          {title}
        </span>
      </div>
    </div>
  );
}
